import { Segment } from './Clock'

export const calculatePosition = (angle: number, radius: number) => {
  const angleRadians = (angle - 90) * (Math.PI / 180) // Convert angle to radians
  return {
    x: radius * Math.cos(angleRadians),
    y: radius * Math.sin(angleRadians),
  }
}

export const filterAndAdjustCurrentHourSegments = (segments: Segment[]): Segment[] => {
  const currentHour = new Date().getHours()
  const currentMinutes = new Date().getMinutes()
  const currentTimeInMinutes = currentHour * 60 + currentMinutes
  const nextHourInMinutes = (currentHour + 1) * 60

  return segments
    .map((segment) => {
      const [startHour, startMinutes] = segment.start.split(':').map(Number)
      const [endHour, endMinutes] = segment.end.split(':').map(Number)
      let startTotalMinutes = startHour * 60 + startMinutes
      let endTotalMinutes = endHour * 60 + endMinutes

      // Adjust for overnight segments
      if (endTotalMinutes < startTotalMinutes) {
        endTotalMinutes += 24 * 60 // Add 24 hours in minutes
      }

      // Check if the segment overlaps with the current hour
      if (
        startTotalMinutes < nextHourInMinutes &&
        endTotalMinutes > currentTimeInMinutes
      ) {
        // Adjust start and end times to fit within the current hour
        return {
          ...segment,
          start:
            Math.max(startTotalMinutes, currentTimeInMinutes) ===
              currentTimeInMinutes
              ? `${currentHour}:${currentMinutes < 10 ? '0' : ''
              }${currentMinutes}`
              : segment.start,
          end:
            Math.min(endTotalMinutes, nextHourInMinutes) === nextHourInMinutes
              ? `${currentHour + 1}:00`
              : segment.end,
        }
      }

      return null
    })
    .filter((segment): segment is Segment => segment !== null)
}

export const timeToAngle = (time: string) => {
  const [hours, minutes] = time.split(':').map((d) => parseFloat(d))
  return ((hours % 12) * 60 + minutes) * 0.5
}

export const minutesToAngle = (minutes: number) => {
  return (minutes / 60) * 360 // 60 minutes in an hour, 360 degrees in a full circle
}

export const filterAndAdjustSegments = (segments: Segment[]): Segment[] => {
  const currentTime = new Date()
  const currentHours = currentTime.getHours()
  const isPM = currentHours >= 12

  return segments
    .map((segment) => {
      const newSegment = { ...segment }
      // Parse start and end hours from the segment
      const [startHours, _startMinutes] = newSegment.start
        .split(':')
        .map(Number)
      const [endHours, _endMinutes] = newSegment.end.split(':').map(Number)

      // Filter out segments that don't belong to the current AM/PM period
      if (
        (isPM && startHours < 12 && endHours < 12) ||
        (!isPM && startHours >= 12 && endHours >= 12)
      ) {
        return null
      }

      // Adjust segment if it spans across the AM/PM boundary
      if ((isPM && endHours < 12) || (!isPM && endHours >= 12)) {
        newSegment.end = isPM ? '23:59' : '11:59'
      }

      // Adjust segment if it spans across the AM/PM boundary
      if ((isPM && startHours < 12) || (!isPM && startHours >= 12)) {
        newSegment.start = isPM ? '12:00' : '00:00'
      }

      return newSegment
    })
    .filter((segment): segment is Segment => segment !== null) // Remove null entries (filtered out segments)
}