import * as d3 from 'd3'
import { useEffect, useState, useRef } from 'react'
import {
  filterAndAdjustCurrentHourSegments,
  calculatePosition,
  timeToAngle,
  minutesToAngle,
  filterAndAdjustSegments,
} from './clock.utils'
import { useClock } from './useClock'

export type Segment = {
  start: string;
  end: string;
  color: string;
  label: string;
}

const Clock = () => {
  const svgContainerRef = useRef(null)

  const [zoomed, setZoomed] = useState(false)

  const currentTime = useClock()

  const width = 600
  const height = 600
  const clockRadius = width / 2
  const clockFaceRadius = clockRadius - 10

  const refreshClock = (svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>) => {
    if (zoomed) {
      drawSegments(svg, filterAndAdjustCurrentHourSegments(hourSegments))
    } else {
      drawSegments(svg, hourSegments)
    }

    drawClockHands(svg)
    drawHours(svg)
    drawMinutesMarkers(svg)
    updateClock(svg)

    if (zoomed) {
      d3.select('.minute-hand').style('display', '')
      d3.select('.hour-hand').style('display', 'none')
    } else {
      d3.select('.hour-hand').style('display', '')
      d3.select('.minute-hand').style('display', 'none')
    }
  }

  useEffect(() => {
    if (!svgContainerRef.current) {
      return
    }

    let exists = d3.select(svgContainerRef.current).select('svg')

    if (!exists.empty()) {
      exists.remove()
    }

    const svg = d3.select(svgContainerRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)

    svg.append('circle')
      .attr('r', clockFaceRadius)
      .attr('fill', 'white')
      .attr('stroke', 'black')
      .attr('stroke-width', 2)

    const interval = setInterval(() => {
      refreshClock(svg)
    }, 1000)

    refreshClock(svg)

    return () => {
      clearInterval(interval)
      svg.remove() // Remove the SVG to prevent duplicates
    }
  }, [zoomed, clockFaceRadius, height, width, svgContainerRef, refreshClock])

  const zoom = () => {
    setZoomed(!zoomed)
  }

  const hourSegments = [
    { start: '07:15', end: '07:50', color: '#FF5733', label: 'eat' },
    { start: '07:50', end: '08:00', color: '#35FF5E', label: 'bathroom' },
    { start: '08:00', end: '08:10', color: '#335BFF', label: 'dress up' },
    { start: '08:10', end: '08:30', color: '#FFC800', label: 'go to school' },
    //  { start: "08:30", end: "11:30", color: "#A82DA0", label: "school" },
    { start: '11:30', end: '11:50', color: '#9A49A1', label: 'back from school' },
    { start: '11:50', end: '12:30', color: '#5FFFC0', label: 'eat' },
    { start: '12:30', end: '13:10', color: '#FF2EFF', label: 'gametime' },
    { start: '13:10', end: '13:30', color: '#FFC800', label: 'go to school' },
    //  { start: "13:30", end: "16:30", color: "#A82DA0", label: "school" },
    { start: '16:30', end: '16:50', color: '#FF8000', label: 'back from school' },
    { start: '16:50', end: '18:00', color: '#9A49A1', label: 'gametime' },
    { start: '18:00', end: '18:30', color: '#FF4040', label: 'prepare food' },
    { start: '18:30', end: '19:15', color: '#FF909F', label: 'eat' },
    { start: '19:15', end: '19:45', color: '#32B6AF', label: 'bathroom' },
    { start: '19:45', end: '07:15', color: '#B8B8D1', label: 'sleep' },
  ]

  let hourHand: d3.Selection<SVGRectElement, unknown, HTMLElement, any> | null = null
  let minuteHand: d3.Selection<SVGRectElement, unknown, HTMLElement, any> | null = null
  let secondHand: d3.Selection<SVGRectElement, unknown, HTMLElement, any> | null = null
  let previousMinutes = 0

  const drawClockHands = (svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>) => {
    if (hourHand) {
      hourHand.remove()
      hourHand = null
    }
    if (minuteHand) {
      minuteHand.remove()
      minuteHand = null
    }
    if (secondHand) {
      secondHand.remove()
      secondHand = null
    }

    hourHand = svg
      .append('rect')
      .attr('class', 'hour-hand')
      .attr('width', 10)
      .attr('height', 125)
      .attr('x', -2.5)
      .attr('y', -125)
      .style('stroke', 'white') // Border color
      .style('stroke-width', '2px') // Border width

    minuteHand = svg
      .append('rect')
      .attr('class', 'minute-hand')
      .attr('width', 5)
      .attr('height', 200)
      .attr('x', -1.5)
      .attr('y', -200)
      .attr('border', 'solid red')
      .attr('fill', 'black')
      .style('stroke', 'white') // Border color
      .style('stroke-width', '1px') // Border width

    secondHand = svg
      .append('rect')
      .attr('class', 'second-hand')
      .attr('width', 2)
      .attr('height', 250)
      .attr('x', -1)
      .attr('y', -250)
      .attr('fill', 'red')
      .style('stroke', 'white') // Border color
      .style('stroke-width', '0.5px') // Border width; // Fill color
  }

  const updateClock = (svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>) => {
    const hours = (currentTime.getHours() % 12) + currentTime.getMinutes() / 60
    const minutes = currentTime.getMinutes()
    const seconds = currentTime.getSeconds()

    if (minutes !== previousMinutes) {
      previousMinutes = minutes
      refreshClock(svg)
    }

    if (!hourHand || !minuteHand || !secondHand) {
      return
    }

    hourHand.attr('transform', `rotate(${(hours / 12) * 360})`)
    minuteHand.attr('transform', `rotate(${(minutes / 60) * 360})`)
    secondHand.attr('transform', `rotate(${(seconds / 60) * 360})`)
  }

  const drawHours = (svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>) => {
    const numberRadius = clockFaceRadius - 20 // Distance from the center of the clock

    for (let number = 1; number <= 12; number++) {
      const angle = (number / 12) * 360 // Angle for each number
      const position = calculatePosition(angle, numberRadius)

      svg
        .append('text')
        .attr('x', position.x)
        .attr('y', position.y)
        .attr('dy', '0.35em') // Center vertically
        .attr('text-anchor', 'middle') // Center horizontally
        .text(number)
        .style('font-size', '20px')
        .style('font-family', 'Arial, sans-serif')
    }
  }

  const drawMinutesMarkers = (svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>) => {
    const markerLength = 10 // Length of each minute marker
    const innerRadius = clockFaceRadius - markerLength
    const outerRadius = clockFaceRadius

    for (let minute = 0; minute < 60; minute++) {
      const angle = (minute / 60) * 360
      const angleRadians = (angle - 90) * (Math.PI / 180) // Convert to radians, adjust for SVG coordinates

      const x1 = innerRadius * Math.cos(angleRadians)
      const y1 = innerRadius * Math.sin(angleRadians)
      const x2 = outerRadius * Math.cos(angleRadians)
      const y2 = outerRadius * Math.sin(angleRadians)

      svg
        .append('line')
        .attr('x1', x1)
        .attr('y1', y1)
        .attr('x2', x2)
        .attr('y2', y2)
        .attr('stroke', 'black')
        .attr('stroke-width', minute % 5 === 0 ? 2 : 1) // Thicker lines for every 5 minutes
    }
  }

  const drawSegment = (svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>, startAngle: number, endAngle: number, color: string) => {
    const arc = d3
      .arc()

    svg
      .append('path')
      .attr('class', 'segment')
      .attr('d', arc({ startAngle: startAngle * (Math.PI / 180), endAngle: endAngle * (Math.PI / 180), innerRadius: 0, outerRadius: clockFaceRadius }))
      .attr('fill', color)
  }

  const drawSegments = (svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>, segments: Segment[]) => {
    const currSegments = filterAndAdjustSegments(segments)

    svg.selectAll('.segment').remove() // Clear existing segments

    // Draw segments
    currSegments.forEach((segment: Segment) => {
      let startAngle
      let endAngle
      if (!zoomed) {
        startAngle = timeToAngle(segment.start)
        endAngle = timeToAngle(segment.end)
      } else {
        const startMinutes = parseInt(segment.start.split(':')[1], 10)
        const endMinutes = parseInt(segment.end.split(':')[1], 10)

        startAngle = minutesToAngle(startMinutes)
        endAngle = minutesToAngle(endMinutes)
      }

      if (endAngle <= startAngle) {
        endAngle += 360 // Add 360 degrees to the end angle to draw the bigger arc
      }

      drawSegment(svg, startAngle, endAngle, segment.color)
    })
  }

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <div>
      <button onClick={zoom}>Zoom</button>
      <div ref={svgContainerRef} style={containerStyle} id="clock-container"></div>
    </div>
  )
}

export default Clock
