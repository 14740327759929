import Clock from './Clock'
import './App.css'

function App() {
  return (
    <div className="App">
      <Clock />
    </div>
  )
}

export default App
